import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_LIST(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`local-organization/primary-group/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`local-organization/primary-group/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('local-organization/primary-group/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`local-organization/primary-group/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`local-organization/primary-group/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_QUERY(state, query = '') {
      return new Promise((resolve, reject) => {
        axios
          .get(`local-organization/primary-group/?query=${query}&paginate=None`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_STAT() {
      return new Promise((resolve, reject) => {
        axios
          .get('stat/local-organization/?choice=group')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_EXPORT() {
      return new Promise((resolve, reject) => {
        axios
          .get('local-organization/primary-group/export/', { responseType: 'blob' })
          .then(response => { resolve(response); })
          .catch(error => { reject(error); });
      });
    },
  }
};