import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_TOP_LIST(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`meeting/supreme-body/?page=${payload.page}&page_size=${payload.limit}&quarter=${payload.quarter}&year=${payload.year}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_TOP_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('meeting/supreme-body/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_MANAGE_LIST(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`meeting/governing-body/?page=${payload.page}&page_size=${payload.limit}&quarter=${payload.quarter}&year=${payload.year}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_MANAGE_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('meeting/governing-body/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_TOP_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`meeting/supreme-body/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_MANAGE_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`meeting/governing-body/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_TOP_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`meeting/supreme-body/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_MANAGE_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`meeting/governing-body/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_TOP(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`meeting/supreme-body/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_MANAGE(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`meeting/governing-body/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_STAT(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`stat/meeting/?choice=${payload.choice}&grouping=false`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}