import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_LIST(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`user/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_DATA(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`user/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_DATA(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('user/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_USER(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`user/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_DATA(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`user/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
};