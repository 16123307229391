import axios from '@/http/axios/axios';

export default {
  namespaced: true,
  actions: {
    GET_DATA() {
      return new Promise((resolve, reject) => {
        axios
          .get('user/me/')
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    PATCH_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch('user/me/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    UPDATE_ORG(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('user/me/update-organization/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
  }
};