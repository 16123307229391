import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_LIST(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`school/person/?page=${payload.page}&page_size=${payload.limit}&quarter=${payload.quarter}&year=${payload.year}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`school/person/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('school/person/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`school/person/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`school/person/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_STAT() {
      return new Promise((resolve, reject) => {
        axios
          .get('stat/school/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_EXPORT() {
      return new Promise((resolve, reject) => {
        axios
          .get('school/person/export/', { responseType: 'blob' })
          .then(response => { resolve(response); })
          .catch(error => { reject(error); });
      });
    },
  }
};