import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_LIST(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`members/man/?status=${payload.status}&region_org=${payload.region_org}&page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_MOVING_LIST(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`members/application/moving/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_MOVING_LIST_INNER(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`members/application/moving/inner/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_REFUSE_LIST(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`members/application/refuse/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_CONTINUE_LIST(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`members/application/continue/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_LOG(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`members/import-log/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_DATA(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`members/man/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('members/man/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_MOVING_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('members/application/moving/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_MOVING_ACCEPT(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`members/application/moving/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_REFUSE_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('members/application/refuse/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_CONTINUE_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('members/application/continue/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`members/man/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_DOWNLOAD() {
      return new Promise((resolve, reject) => {
        axios({
          url: 'members/journal/example/',
          responseType: 'blob'
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_REPORT(state, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `members/import-log/${payload}/report/`,
          responseType: 'blob'
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_SEARCH(state, payload = '') {
      return new Promise((resolve, reject) => {
        axios
          .get(`members/man/search/?query=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_JOURNAL_SEARCH(state, payload = '') {
      return new Promise((resolve, reject) => {
        axios
          .get(`members/man/search/?query=${payload}&journal=true`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_EXPORT() {
      return new Promise((resolve, reject) => {
        axios
          .get('members/journal/export/', { responseType: 'blob' })
          .then(response => { resolve(response); })
          .catch(error => { reject(error); });
      });
    },
  }
};