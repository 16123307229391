import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_LIST(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`event/plan/?page=${payload.page}&page_size=${payload.limit}&quarter=${payload.quarter}&year=${payload.year}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`event/plan/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('event/plan/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`event/plan/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`event/plan/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_STAT() {
      return new Promise((resolve, reject) => {
        axios
          .get('stat/event-plan/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_LEVEL() {
      return new Promise((resolve, reject) => {
        axios
          .get('dictionary/event/level/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
};