import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_DATA() {
      return new Promise((resolve, reject) => {
        axios
          .get('head/questionnaire/detail/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('head/questionnaire/new/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch('head/questionnaire/update/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_LANG() {
      return new Promise((resolve, reject) => {
        axios
          .get('dictionary/language/?paginate=None')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_LEVEL() {
      return new Promise((resolve, reject) => {
        axios
          .get('dictionary/language/level/?paginate=None')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_STAT() {
      return new Promise((resolve, reject) => {
        axios
          .get('stat/head/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_EXPORT() {
      return new Promise((resolve, reject) => {
        axios
          .get('head/questionnaire/export/', { responseType: 'blob' })
          .then(response => { resolve(response); })
          .catch(error => { reject(error); });
      });
    },
  }
}