import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_PARTICIPATION_LIST(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`contest/participation/?page=${payload.page}&page_size=${payload.limit}&quarter=${payload.quarter}&year=${payload.year}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_PARTICIPATION_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('contest/participation/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_VICTORY_LIST(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`contest/victory/?page=${payload.page}&page_size=${payload.limit}&quarter=${payload.quarter}&year=${payload.year}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_VICTORY_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('contest/victory/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_PARTICIPATION_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`contest/participation/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_VICTORY_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`contest/victory/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_PARTICIPATION_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`contest/participation/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_VICTORY_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`contest/victory/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_PARTICIPATION(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`contest/participation/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_VICTORY(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`contest/victory/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_STAT(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`stat/contest/?choice=${payload.choice}&grouping=false`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_EXPORT() {
      return new Promise((resolve, reject) => {
        axios
          .get('contest/export/', { responseType: 'blob' })
          .then(response => { resolve(response); })
          .catch(error => { reject(error); });
      });
    },
  }
};