import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/http/axios/axios'
import createPersistedState from 'vuex-persistedstate'
import auth from './auth'
import profile from './profile'
import users from './users'
import regOrg from './regOrg'
import localOrg from './localOrg'
import grant from './grant'
import status from './status'
import agreement from './agreement'
import balance from './balance'
import justice from './justice'
import meeting from './meeting'
import information from './information'
import social from './social'
import calendar from './calendar'
import government from './government'
import eventPlan from './eventPlan'
import journal from './journal'
import reason from './reason'
import program from './program'
import head from './head'
import passport from './passport'
import school from './school'
import stats from './stats'
import primary from './primary'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  namespaced: true,
  state: {
    sidebar: false,
    sidebarOpen: false,
    firstName: '',
    lastName: '',
    id: '',
    organization: '',
    organizationName: '',
    userRole: 'admin',
  },
  mutations: {
    sidebarToggle(state) {
      state.sidebar = !state.sidebar
    },
    sidebarMobileToggle(state) {
      state.sidebarOpen = !state.sidebarOpen
    },
    setFirstName (state, payload) {
      state.firstName = payload
    },
    setLastName (state, payload) {
      state.lastName = payload
    },
    setId (state, payload) {
      state.id = payload
    },
    setOrganization (state, payload) {
      state.organization = payload
    },
    setUserRole (state, payload) {
      state.userRole = payload
    },
    setOrganizationName(state, payload) {
      state.organizationName = payload
    },
  },
  actions: {
    POST_FILE(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('files/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    FIND_ORGANIZATION(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`find-org/?q=${payload.search}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    FIND_ADDRESS(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`find-address/?q=${payload.search}&level=7,8,65,9`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    FIND_BIK(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`find-bank/?q=${payload.search}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_PARTNER_TYPE() {
      return new Promise((resolve, reject) => {
        axios
          .get('dictionary/partner-type/?paginate=None')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
  modules: {
    auth,
    profile,
    users,
    regOrg,
    localOrg,
    grant,
    status,
    agreement,
    balance,
    justice,
    meeting,
    information,
    social,
    calendar,
    government,
    eventPlan,
    journal,
    reason,
    program,
    head,
    passport,
    school,
    stats,
    primary,
  }
})
