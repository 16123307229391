import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_LIST(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`local-organization/main/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`local-organization/main/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('local-organization/main/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`local-organization/main/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`local-organization/main/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_QUERY(state, query = '') {
      return new Promise((resolve, reject) => {
        axios
          .get(`local-organization/main/?query=${query}&paginate=None`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_INREG_QUERY(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`dictionary/region/local-organization/?query=${payload.query}&region_org=${payload.region_org}&paginate=None`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_STAT() {
      return new Promise((resolve, reject) => {
        axios
          .get('stat/local-organization/?choice=organization')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_EXPORT() {
      return new Promise((resolve, reject) => {
        axios
          .get('local-organization/main/export/', { responseType: 'blob' })
          .then(response => { resolve(response); })
          .catch(error => { reject(error); });
      });
    },
  }
};