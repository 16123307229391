import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_CARDS(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`statistic/?region_org=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_GRAPH(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`statistic/period/?year=${payload.year}&from=${payload.from}&to=${payload.to}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_EXPORT() {
      return new Promise((resolve, reject) => {
        axios
          .get('statistic/export/', { responseType: 'blob' })
          .then(response => { resolve(response); })
          .catch(error => { reject(error); });
      });
    },
  }
};