import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_REFUSE_LIST() {
      return new Promise((resolve, reject) => {
        axios
          .get('dictionary/journal/refuse/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_CONTINUE_LIST() {
      return new Promise((resolve, reject) => {
        axios
          .get('dictionary/journal/continue/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}