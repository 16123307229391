import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_LIST(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`dictionary/calendar/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`dictionary/calendar/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('dictionary/calendar/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`dictionary/calendar/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`dictionary/calendar/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_EVENTS_LIST(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`event/plan/main/?region=${payload.region}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_EVENTS_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`event/plan/${payload}/main/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
};