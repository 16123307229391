<template>
  <div class="sidebar"
       :class="{ 'sidebar--mini': $store.state.sidebar, 'sidebar--open': $store.state.sidebarOpen && sidebarOpen}"
       @mouseenter="showMenu = true"
       @mouseleave="showMenu = false"
  >
    <div class="sidebar__top">
      <router-link :to="{ name: 'report' }" class="sidebar__logo">
        <img src="@/assets/img/sidebar/sidebar-logo.svg" alt="main logo" />
        <img src="@/assets/img/sidebar/main-leaf.svg" alt="main logo" />
      </router-link>
      <button
        class="sidebar__toggler"
        :class="{ 'is-hidden': $store.state.sidebar }"
        type="button"
        @click="toggleSidebar"
      ></button>
      <button
        @click="sidebarMobileClose"
        class="sidebar__close"
      >
      </button>
    </div>
    <div class="sidebar__user">
      <div class="user" :class="{ 'user--hide': $store.state.sidebar && !showMenu }">
        <router-link
          :to="{ name: 'profile' }"
          class="user__info"
        >
          <span v-if="firstName" class="user__name">{{ firstName }} {{ lastName }}</span>
          <span v-else class="user__name">Заполнить</span>
          <span class="user__id"><b>ID:</b>{{ userId }}</span>
        </router-link>
        <button
          @click="logout"
          class="user__logout"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 8.25V6.375C15 5.87772 14.8025 5.40081 14.4508 5.04917C14.0992 4.69754 13.6223 4.5 13.125 4.5H4.125C3.62772 4.5 3.15081 4.69754 2.79917 5.04917C2.44754 5.40081 2.25 5.87772 2.25 6.375V17.625C2.25 18.1223 2.44754 18.5992 2.79917 18.9508C3.15081 19.3025 3.62772 19.5 4.125 19.5H13.125C13.6223 19.5 14.0992 19.3025 14.4508 18.9508C14.8025 18.5992 15 18.1223 15 17.625V15.75" stroke="#323F4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18 8.25L21.75 12L18 15.75" stroke="#323F4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.95312 12H21.75" stroke="#323F4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </div>
    <div class="sidebar__menu">
      <Menu :show="showMenu || $store.state.sidebarOpen" :mobileShow="$store.state.sidebarOpen"/>
      <div class="sidebar__line"></div>
      <div class="sidebar__block">
        <div class="sidebar__title">Техническая поддержка</div>
        <div class="sidebar__links">
          <a :href="link.url" class="sidebar__link" v-for="link in links" :key="link.id">
            <img :src="require('@/assets/img/sidebar/' + link.icon)" :alt="link.icon">
            <span>{{ link.text }}</span>
          </a>
        </div>
        <div class="sidebar__line sidebar__line--100"></div>
        <div class="sidebar__text">
          Единая система учёта Российского Союза Молодежи
        </div>
        <a href="https://antonov.group/" target="_blank" class="sidebar__icon">
          <img src="@/assets/img/sidebar/poweredbyblue.svg" alt="">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from './Menu';
import auth from '@/http/requests/auth';

export default {
  name: 'Sidebar',
  components: {
    Menu
  },
  data () {
    return {
      links: [
        { id: 1, text: '+7 (928) 014-72-72', url: 'tel:+79280147272', icon: 'phone-icon.svg' },
        { id: 3, text: 'antonov_group', url: 'tg://resolve?domain=antonov_group', icon: 'tg-blue.svg' },
        { id: 2, text: 'support@antonov.group', url: 'mailto:support@antonov.group', icon: 'link-icon.svg' },
        { id: 4, text: 'ПН-ПТ 10:00 - 18:00 (Москва)', url: '', icon: 'time-blue.svg' },
      ],
      showMenu: false,
      wWidth: window.innerWidth <= 1100,
    };
  },
  computed: {
    sidebarOpen() {
      return this.wWidth <= 1100
    },
    firstName() {
      return this.$store.state.firstName
    },
    lastName() {
      return this.$store.state.lastName
    },
    userId() {
      return this.$store.state.id
    }
  },
  methods: {
    toggleSidebar () {
      this.$store.commit('sidebarToggle')
    },
    sidebarMobileClose() {
      if (this.$store.state.sidebarOpen) this.$store.commit('sidebarMobileToggle')
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    logout() {
      auth.logout();
      this.$router.push({ name: 'login' });
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    if (localStorage.getItem('accessToken')) {
      this.$store.dispatch('profile/GET_DATA')
        .then(response => {
          this.$store.commit('setFirstName', response.data.first_name)
          this.$store.commit('setLastName', response.data.last_name)
          this.$store.commit('setId', response.data.id)
          this.$store.commit('setOrganization', response.data.organization)
          this.$store.commit('setOrganizationName', response.data.organization_name)
          if (!response.data.groups.length) {
            this.$store.commit('setUserRole', 'admin')
          } else if (response.data.groups[0] === 1) {
            this.$store.commit('setUserRole', 'region')
          } else {
            this.$store.commit('setUserRole', 'local')
          }
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка получения данных'
          })
        })
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.sidebar
  position: fixed
  top: 0
  left: 0
  z-index: 99
  width: 300px
  height: 100%
  background-color: #f8fdff
  box-shadow: 0 4px 14px rgba(172, 196, 206, 0.4)
  transition: width 0.3s, transform 0.3s

  +max-w($laptop_sm)
    transform: translateX(-100%)

.sidebar--mini:not(.sidebar--open)
  width: 92px

  .sidebar__logo
    margin-left: 0
    text-align: center

    img
      &:first-child
        display: none
        max-width: none

      &:last-child
        display: block
        width: 45px
        margin: 0 auto

  .sidebar__block
    display: none

  .sidebar__line
    display: none

  .user__logout
    display: none

  &:hover
    .sidebar__logo
      margin-left: 25px

      img
        &:first-child
          display: block

        &:last-child
          display: none

    .sidebar__block
      display: block

    .sidebar__line
      display: block

    .user__logout
      display: block

  .sidebar__toggler
    display: none

  &:hover
    width: 300px

    .sidebar__toggler
      display: block

    .sidebar__menu
      overflow-y: auto

  .sidebar__menu
    overflow-y: hidden

.sidebar--open
  transform: translateX(0)

.sidebar__logo
  position: relative
  display: block
  margin-left: 25px
  padding: 30px 0
  line-height: 0
  overflow: hidden

  img
    &:first-child
      width: 200px

    &:last-child
      display: none

.sidebar__top
  position: relative

.sidebar__toggler
  position: absolute
  right: 17px
  top: 50%
  z-index: 1
  display: flex
  align-items: center
  justify-content: center
  width: 16px
  height: 16px
  border-radius: 50%
  border: 1px solid $color-theme
  transform: translateY(-50%)

  &::after
    content: ""
    width: 6px
    height: 6px
    border-radius: 50%
    background-color: $color-theme

  &.is-hidden::after
    content: ""

  +max-w($laptop_sm)
    display: none

.sidebar__part
  margin: 22px 0 20px

.sidebar__menu
  height: calc(100vh - 199px)
  padding-bottom: 20px
  overflow-x: hidden
  overflow-y: scroll
  scrollbar-color: $color-theme
  scrollbar-width: thin

  &::-webkit-scrollbar
    width: 2px

  &::-webkit-scrollbar-track
    border-radius: 5px
    background-color: #f2f3f8

  &::-webkit-scrollbar-thumb
    border-radius: 5px
    background-color: $color-theme

.sidebar__line
  width: 80%
  height: 1px
  margin: 10px auto 24px
  background-color: $color-border

.sidebar__line--100
  width: 100%

.sidebar__block
  padding: 0 34px

.sidebar__title
  margin-bottom: 15px
  font-size: 16px
  font-weight: 600

.sidebar__links
  margin-bottom: 22px

.sidebar__link
  display: flex
  align-items: flex-start
  padding: 11px 0
  color: $color-text-base
  text-decoration: none
  transition: color 0.3s

  img
    flex-shrink: 0
    margin-right: 18px

  span
    font-size: 16px

  &:hover
    color: $color-theme

.sidebar__text
  margin-bottom: 25px
  font-size: 16px

.sidebar__close
  position: absolute
  right: 17px
  top: 50%
  z-index: 1
  width: 25px
  height: 25px
  padding: 2px
  cursor: pointer
  transform: translateY(-60%)

  &:before,
  &:after
    content: ''
    position: absolute
    top: 50%
    left: 2px
    width: 20px
    height: 2px
    border-radius: 1px
    background-color: $color-theme

  &:before
    transform: rotate(45deg)

  &:after
    transform: rotate(-45deg)

  +min-w($laptop_sm)
    display: none

.sidebar__user
  position: relative
  z-index: 5
  border-top: 1px solid #E7EEF4
  border-bottom: 1px solid #E7EEF4
  box-shadow: -15px 8px 11px #f8fdff
</style>
