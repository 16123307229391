import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_OFFICIAL_LIST(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`info-support/profile/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_OFFICIAL_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('info-support/profile/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_OFFICIAL_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`info-support/profile/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_OFFICIAL_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`info-support/profile/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_OFFICIAL(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`info-support/profile/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_FEDERAL_LIST(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`info-support/federal-smi/?page=${payload.page}&page_size=${payload.limit}&quarter=${payload.quarter}&year=${payload.year}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_FEDERAL_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('info-support/federal-smi/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_FEDERAL_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`info-support/federal-smi/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_FEDERAL_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`info-support/federal-smi/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_FEDERAL(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`info-support/federal-smi/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_REGIONAL_LIST(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`info-support/region-smi/?page=${payload.page}&page_size=${payload.limit}&quarter=${payload.quarter}&year=${payload.year}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_REGIONAL_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('info-support/region-smi/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_REGIONAL_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`info-support/region-smi/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_REGIONAL_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`info-support/region-smi/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_REGIONAL(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`info-support/region-smi/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_EDUCATION_LIST(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`info-support/publication/?page=${payload.page}&page_size=${payload.limit}&quarter=${payload.quarter}&year=${payload.year}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_EDUCATION_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('info-support/publication/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_EDUCATION_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`info-support/publication/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_EDUCATION_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`info-support/publication/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_EDUCATION(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`info-support/publication/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_STAT() {
      return new Promise((resolve, reject) => {
        axios
          .get('stat/info-support/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}