import axios from '@/http/axios/axios';
import jwt from '@/http/requests/auth';

export default {
  namespaced: true,
  state: {
    isUserLoggedIn: () => {
      return localStorage.getItem('accessToken');
    }
  },
  mutations: {
    SET_BEARER (state, accessToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
  },
  actions: {
    LOGIN_JWT({ commit }, payload) {
      return new Promise((resolve, reject) => {
        jwt.login(payload.username, payload.password)
          .then(response => {
            if (response.data.access) {
              localStorage.setItem('accessToken', response.data.access);
              localStorage.setItem('refreshToken', response.data.refresh);
              commit('SET_BEARER', response.data.access);
              resolve(response);
            } else {
              reject({message: 'Неверное имя или пароль'});
            }
          })
          .catch(() => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            reject({message: 'Неверное имя или пароль'});
          });
      });
    },
    FETCH_ACCESS_TOKEN () {
      return new Promise((resolve) => {
        jwt.refreshToken().then(response => { resolve(response); });
      });
    },
  }
};
