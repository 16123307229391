import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_ORG_DATA() {
      return new Promise((resolve, reject) => {
        axios
          .get('passport/questionnaire/detail/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_ORG_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('passport/questionnaire/new/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_ORG_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch('passport/questionnaire/update/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_HEAD_LIST(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`passport/governing-body/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_HEAD_DATA(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`passport/governing-body/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_HEAD_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('passport/governing-body/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_HEAD_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`passport/governing-body/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_HEAD_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`passport/governing-body/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_CONTROL_DATA() {
      return new Promise((resolve, reject) => {
        axios
          .get('passport/audit-body/detail/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_CONTROL_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('passport/audit-body/new/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_CONTROL_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch('passport/audit-body/update/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_WORKERS_LIST(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`passport/worker/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_WORKERS_DATA(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`passport/worker/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_WORKERS_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('passport/worker/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_WORKERS_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`passport/worker/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_WORKERS_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`passport/worker/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_ADVISORY_LIST(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`passport/participant/council/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_ADVISORY_DATA(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`passport/participant/council/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_ADVISORY_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('passport/participant/council/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_ADVISORY_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`passport/participant/council/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_ADVISORY_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`passport/participant/council/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_PUBLIC_LIST(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`passport/participant/chamber/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_PUBLIC_DATA(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`passport/participant/chamber/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_PUBLIC_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('passport/participant/chamber/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_PUBLIC_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`passport/participant/chamber/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_PUBLIC_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`passport/participant/chamber/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_NAME_DATA() {
      return new Promise((resolve, reject) => {
        axios
          .get('passport/manage-body/detail/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_NAME_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('passport/manage-body/new/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_NAME_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch('passport/manage-body/update/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_SUPPORT_DATA() {
      return new Promise((resolve, reject) => {
        axios
          .get('passport/support/form/detail/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_SUPPORT_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('passport/support/form/new/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_SUPPORT_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch('passport/support/form/update/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_PARTNER_LIST(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`passport/partner/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_PARTNER_DATA(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`passport/partner/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_PARTNER_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('passport/partner/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_PARTNER_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`passport/partner/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_PARTNER_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`passport/partner/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_IMMOVABLES_LIST(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`passport/real-estate/?page=${payload.page}&page_size=${payload.limit}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_IMMOVABLES_DATA(state, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`passport/real-estate/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_IMMOVABLES_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('passport/real-estate/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_IMMOVABLES_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`passport/real-estate/${payload.id}/`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_IMMOVABLES_DATA(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`passport/real-estate/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_IMMOVABLES_STAT() {
      return new Promise((resolve, reject) => {
        axios
          .get('stat/passport/real-estate/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_EXPORT() {
      return new Promise((resolve, reject) => {
        axios
          .get('passport/main/export/', { responseType: 'blob' })
          .then(response => { resolve(response); })
          .catch(error => { reject(error); });
      });
    },
  }
};