<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
    <notifications classes="my-notifi" width="500" :duration="3000" />
  </div>
</template>

<script>
export default {
  computed: {
    layout () {
      return this.$route.meta.layout ?? 'default'
    }
  },
}
</script>

<style lang="sass">
#app
  display: flex
  flex-direction: column
  flex-grow: 1
</style>
