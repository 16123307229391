import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_LIST() {
      return new Promise((resolve, reject) => {
        axios
          .get(`dictionary/region/organization/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_QUERY(state, query = '') {
      return new Promise((resolve, reject) => {
        axios
          .get(`dictionary/region/organization/?query=${query}&paginate=None`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_REGION(state, query = '') {
      return new Promise((resolve, reject) => {
        axios
          .get(`dictionary/region/subject/?query=${query}&paginate=None`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
};