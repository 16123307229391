<template>
  <div class="public">
    <div class="public__container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.public
  margin-bottom: 100px

  &::before
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 733px
    background-image: url("../assets/img/public/public-bg.svg")
    background-repeat: no-repeat
    background-position: center
    background-size: cover

  +max-w($mobile_md)
    margin-bottom: 0

.public__container
  max-width: 844px
  margin: 0 auto
  padding: 100px 20px 0

  +max-w($mobile_md)
    padding: 40px 0 0
</style>
