<template>
  <div class="wrapper">
    <Sidebar />
    <main class="main" :class="{ 'main--full': $store.state.sidebar }">
<!--      <Header />-->
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </main>
  </div>
</template>

<script>
import Sidebar from '@/components/layout/Sidebar'
// import Header from '@/components/layout/Header';

export default {
  components: {
    Sidebar,
    // Header,
  },
};
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.wrapper
  flex-grow: 1
  display: flex
  width: 100%
  height: 100%
  flex-direction: column

.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>